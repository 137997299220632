import { template as template_aaab0d3de194421b85b862cfe0038f89 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aaab0d3de194421b85b862cfe0038f89(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
