import { template as template_387f8ac02d3b45c7a2f2480c199ce88e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_387f8ac02d3b45c7a2f2480c199ce88e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
