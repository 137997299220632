import { template as template_f6019c45ce7d4873a8aa2005c43a0d2d } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_f6019c45ce7d4873a8aa2005c43a0d2d(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
