import { template as template_383dc22021ef4d2c874d16b4a0f5c89b } from "@ember/template-compiler";
const EmptyState = template_383dc22021ef4d2c874d16b4a0f5c89b(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
