import { template as template_8fbe487b3e424af793b02764fb4bae6f } from "@ember/template-compiler";
const FKLabel = template_8fbe487b3e424af793b02764fb4bae6f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
