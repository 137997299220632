import { template as template_0498d805a2a34349aa0c9578102a6729 } from "@ember/template-compiler";
const FKText = template_0498d805a2a34349aa0c9578102a6729(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
